import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
} from '@material-ui/core';
import InlineButton from '../../../components/InlineButton';
import UserRepository from '../repository/UserRepository';

type MergeUsersDialogProps = {
  open: boolean;
  onClose: () => void;
};

const MergeUsersDialog = (props: MergeUsersDialogProps) => {
  const { open, onClose } = props;

  const notifications = useSnackbar();

  const [emailToKeep, setEmailToKeep] = useState<string>('');
  const [emailToKeepError, setEmailToKeepError] = useState<boolean>(false);
  const [emailToDiscard, setEmailToDiscard] = useState<string>('');
  const [emailToDiscardError, setEmailToDiscardError] =
    useState<boolean>(false);

  const mergeUsers = (emailToKeep: string, emailToDiscard: string) => {
    setEmailToKeepError(false);
    setEmailToDiscardError(false);
    new UserRepository()
      .mergeUsers(emailToKeep, emailToDiscard)
      .then(() => {
        notifications.enqueueSnackbar(
          'De gebruikers zijn succesvol samengevoegd.',
          { variant: 'success' },
        );
        onClose();
      })
      .catch((error) => {
        if (
          'response' in error &&
          'status' in error.response &&
          'data' in error.response &&
          error.response.status === 422 &&
          'violations' in error.response.data &&
          Array.isArray(error.response.data.violations) &&
          error.response.data.violations.length >= 1
        ) {
          const { violations } = error.response.data;

          // check simple validation errors first
          let hasEmailError: boolean = false;
          violations.forEach((violation: any) => {
            if ('propertyPath' in violation) {
              switch (violation.propertyPath) {
                case 'emailToKeep':
                  hasEmailError = true;
                  setEmailToKeepError(true);
                  break;
                case 'emailToDiscard':
                  hasEmailError = true;
                  setEmailToDiscardError(true);
                  break;
                default:
                  break;
              }
            }
          });

          if (hasEmailError) {
            return;
          }

          // then check critical validation errors
          const messages: string[] = [];
          violations.forEach((violation: any) => {
            if ('propertyPath' in violation) {
              switch (violation.propertyPath) {
                case 'providers':
                  messages.push(
                    'Samenvoegen is niet mogelijk, omdat de gebruikers bij verschillende zwemlesaanbieders horen.',
                  );
                  break;
                case 'operators':
                  messages.push(
                    'Samenvoegen is niet mogelijk, omdat de gebruikers bij verschillende exploitanten horen.',
                  );
                  break;
                default:
                  break;
              }
            }
          });

          if (messages.length >= 1) {
            notifications.enqueueSnackbar(messages.join(' '), {
              variant: 'error',
            });

            return;
          }
        }

        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het samenvoegen van gebruikers.',
          { variant: 'error' },
        );
      });
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Gebruikers samenvoegen</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <FormLabel id="userEmailToKeep">
            E-mailadres van gebruiker die moet blijven
          </FormLabel>
          <TextField
            id="userEmailToKeep"
            type="text"
            fullWidth
            value={emailToKeep}
            onChange={(e) => setEmailToKeep(e.target.value)}
            error={emailToKeepError}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel id="userEmailToDiscard">
            E-mailadres van gebruiker die verwijderd moet worden
          </FormLabel>
          <TextField
            id="userEmailToDiscard"
            type="text"
            fullWidth
            value={emailToDiscard}
            onChange={(e) => setEmailToDiscard(e.target.value)}
            error={emailToDiscardError}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <InlineButton type="default" onClick={() => onClose()}>
          Annuleren
        </InlineButton>
        <InlineButton
          type="dangerous"
          onClick={() => mergeUsers(emailToKeep, emailToDiscard)}
        >
          Samenvoegen
        </InlineButton>
      </DialogActions>
    </Dialog>
  );
};

export default MergeUsersDialog;
